figure.jekyll_figcaption {
  flex-flow: column;
  margin: auto;
}

figcaption.jekyll_figcaption {
  background-color: #222;
  color: #fff;
  font: smaller sans-serif;
  padding: 3px;
  text-align: center;
}

aside {
  background: #1f8dd6;
  margin: 1em 0;
  padding: 2em 1em;
  border-radius: 3px;
  color: #fff;
  line-height: 1.6;
}

aside a, aside a:visited {
  color: #a9e2ff;
}

aside code {
  font-size: 90%;
  padding: .2em .4em;
  white-space: nowrap;
  color: #333;
  background: #fafafa;
}

ul.share-buttons{
  list-style: none;
  padding: 30px;
  text-align: center;
}

ul.share-buttons li{
  display: inline;
}

ul.share-buttons .sr-only{
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.post-meta a{
  color: $grey-color;
}
